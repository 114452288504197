import { SET_ERROR, CLEAR_ERROR } from "../constants";

const initialState = {
    profitOrder: [],
    lossOrder: [],
    menuOrder: {},
    menuStatus: false

};

export default (state = initialState, { type, payload }) => {
    let { profitOrder, lossOrder } = state
    let index = null;
    switch (type) {
        case 'UPDATE_PROFIT_ORDER':
            lossOrder = lossOrder.filter(x => x.id !== payload.id)
            index = profitOrder.findIndex(x => x.id == payload.id)
            if (index != -1 && index != null) {
                profitOrder[index] = payload
            } else {
                profitOrder.push(payload)
            }
            return {
                ...state,
                lossOrder,
                profitOrder
            }
            break;
        case 'UPDATE_LOSS_ORDER':
            profitOrder = profitOrder.filter(x => x.id !== payload.id)
            index = lossOrder.findIndex(x => x.id == payload.id)
            if (index != -1 && index != null) {
                lossOrder[index] = payload
            } else {
                lossOrder.push(payload)
            }
            return {
                ...state,
                lossOrder,
                profitOrder
            }
            break;
        case 'SET_MENU_ORDER':
            return {
                ...state,
                menuOrder: payload,
                menuStatus: true
            }

        case 'UNSET_MENU_ORDER':
            return {
                ...state,
                menuOrder: {},
                menuStatus: false
            }
            break;
        case 'MISC_INIT':
            return {
                profitOrder: [],
                lossOrder: [],
                menuOrder: {},
                menuStatus: false

            };
            break;
        default:
            return state;
    }
};
