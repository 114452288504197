const initialState = {
    lang: {
        name: 'English',
        symbol: 'en',
        direction: 'ltr'
    }
};

export default (state = initialState, { type, payload }) => {
    switch (type) {
        case 'CHANGE_LANG':
            return {
                lang: payload
            }
            break;
        default:
            return state;
    }
};
