const LOAD_USER = "LOAD_USER";
const UNLOAD_USER = "UNLOAD_USER";
const UPDATE_USER = "UPDATE_USER";
const LOG_OUT = "LOG_OUT";

const LOADING_ON = "LOADING_ON";
const LOADING_OFF = "LOADING_OFF";
const SET_USER = "SET_USER";

const LOAD_DATA = "LOAD_DATA";

//error
const SET_ERROR = "SET_ERROR";
const CLEAR_ERROR = "CLEAR_ERROR";

//config

const CONFIG_LOADING = "CONFIG_LOADING";
const CONFIG_LOAD = "CONFIG_LOAD";

//user

const USERS_LOADING = "USERS_LOADING";
const USERS_LOAD = "USERS_LOAD";

//users
const ONLINE_USERS_LOADING = "ONLINE_USERS_LOADING";
const ONLINE_USERS_LOAD = "ONLINE_USERS_LOAD";

//orderS
const ORDERS_LOADING = "ORDERS_LOADING";
const ORDERS_LOAD = "ORDERS_LOAD";

//tickets
const TICKETS_LOADING = "TICKETS_LOADING";
const TICKETS_LOAD = "TICKETS_LOAD";

//payout
const PAYOUT_LOADING = "PAYOUT_LOADING";
const PAYOUT_LOAD = "PAYOUT_LOAD";


module.exports = {
  LOAD_USER,
  UNLOAD_USER,
  UPDATE_USER,
  LOG_OUT,
  LOADING_OFF,
  LOADING_ON,
  SET_USER,
  LOAD_DATA,
  SET_ERROR,
  CLEAR_ERROR,

  CONFIG_LOADING,
  CONFIG_LOAD,

  USERS_LOADING,
  USERS_LOAD,
  ONLINE_USERS_LOADING,
  ONLINE_USERS_LOAD,

  ORDERS_LOADING,
  ORDERS_LOAD,

  TICKETS_LOAD,
  TICKETS_LOADING,

  PAYOUT_LOADING,
  PAYOUT_LOAD


}
