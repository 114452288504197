import React, { useEffect } from "react";

import { useSelector, useDispatch } from "react-redux";

import { Route, Switch, Redirect, BrowserRouter } from "react-router-dom";

import "./scss/style.scss";
import "./scss/cryptinix.css";
import "react-notifications/lib/notifications.css";

import { axios, getHeader } from "./config/server";

import { ToastProvider } from "react-toast-notifications";

import { NotificationContainer } from "react-notifications";
import auth from "./store/reducers/auth";

const loading = (
  <div className="pt-3 text-center">
    <div className="sk-spinner sk-spinner-pulse"></div>
  </div>
);

// Containers
const TheLayout = React.lazy(() => import("./containers/TheLayout"));

// Pages
const Login = React.lazy(() => import("./views/auth/Login"));
const Register = React.lazy(() => import("./views/auth/Register"));
const Logout = React.lazy(() => import("./views/auth/Logout"));
const MailVerification = React.lazy(() =>
  import("./views/auth/MailVerification")
);
const Home = React.lazy(() => import("./views/auth/Home"));
const Tfa = React.lazy(() => import("./views/auth/Tfa"));

const Announcement = React.lazy(() => import("./views/auth/Announcement"));
const News = React.lazy(() => import("./views/auth/News"));
const Blogs = React.lazy(() => import("./views/auth/Blogs"));
const Blogview = React.lazy(() => import("./views/auth/Blogview"));

const FindAccount = React.lazy(() => import("./views/auth/ForgotPassword"));
const Chart = React.lazy(() => import("./views/auth/components/Chart/index"));

// const Register = React.lazy(() => import('./views/pages/register/Register'));
// const Page404 = React.lazy(() => import('./views/pages/page404/Page404'));
// const Page500 = React.lazy(() => import('./views/pages/page500/Page500'));

const App = () => {
  const isAuthenticated = useSelector((state) => state.auth.isAuthenticated);
  const tfaVarified = useSelector((state) => state.auth.tfaVarified);
  const tfa = useSelector((state) => state.auth.tfa);
  const mailStatus = useSelector((state) => state.auth.user.mailStatus);

  const dispatch = useDispatch();

  const PublicRoute = ({ component: Component, ...rest }) => {
    return (
      <Route
        path="/"
        render={(props) =>
          !isAuthenticated ? <Component {...props} /> : <Redirect to="/trade" />
        }
      />
    );
  };

  const PrivateRoute = ({ component: Component, ...rest }) => {
    return (
      <Route
        path="/"
        render={(props) => {
          if (isAuthenticated && !mailStatus) {
            return <MailVerification {...props} />;
          } else if (isAuthenticated && tfa && !tfaVarified) {
            return <Tfa {...props} />;
          } else if (isAuthenticated) {
            return <Component {...props} />;
          } else {
            return <Redirect to="/login" />;
          }

          // return isAuthenticated?<Component {...props} />:<Redirect to='/login' />
        }}
      />
    );
  };

  // const loadMasterData = async () => {
  //   await axios.get('/auth/get-master-data', getHeader(auth))
  //     .then((response) => {
  //       dispatch({ type: 'SET_MASTER_DATA', payload: response })
  //     }).catch(e => {
  //       dispatch({ type: 'SET_ERROR', payload: { ...e, type: 'master-data' } });
  //       return false
  //     })
  // }
  // useEffect(() => {
  //   loadMasterData();
  // }, [])

  return (
    <ToastProvider autoDismiss autoDismissTimeout={6000} placement="top-left">
      <BrowserRouter>
        <React.Suspense fallback={loading}>
          <Switch>
            <Route
              exact
              path="/logout"
              name="Logout"
              render={(props) => <Logout {...props} />}
            />
            {/* <Route exact path="/404" name="Page 404" render={props => <Page404 {...props} />} />
              <Route exact path="/500" name="Page 500" render={props => <Page500 {...props} />} /> */}
            <PublicRoute path="/login" name="Login Page" component={Login} a />
            <PublicRoute
              path="/register"
              name="Register Page"
              component={Register}
            />
            <PublicRoute
              path="/find-account"
              name="Find Account"
              component={FindAccount}
            />
            <PrivateRoute path="/trade" name="Home" component={TheLayout} />

            <Route exact path="/" name="Home" component={Home} />
            <Route
              exact
              path="/announcements"
              name="Announcements"
              component={Announcement}
            />
            <Route exact path="/news" name="News" component={News} />
            <Route
              exact
              path="/chart/:symbol/:int/:type?/:theme?"
              name="Chart"
              component={Chart}
            />
            <Route exact path="/blogs" name="Blogs" component={Blogs} />
            <Route
              exact
              path="/blog/:id"
              name="BlogView"
              component={Blogview}
            />
            {/* 
            <PublicRoute path="/" name="Register Page"
              component={Home}
            /> */}
          </Switch>
        </React.Suspense>
      </BrowserRouter>
    </ToastProvider>
  );
};

export default App;
