import { SET_ERROR, CLEAR_ERROR, ORDERS_LOAD } from "../constants";

const initialState = {
  error: false,
  errors: [],
  type: '',
  msg: ''
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case SET_ERROR:

      return {
        ...state,
        error: true,
        errors: payload.errors,
        msg: (payload.errors && payload.errors[0].msg) || '',
        type: payload.type
      };

    case CLEAR_ERROR:
    case ORDERS_LOAD:
    case 'CONFIG_LOAD':
    case 'USERS_LOAD':
    case 'LOAD_USER':
    case 'SET_MASTER_DATA':
      return {
        error: false,
        errors: [],
        msg: ''
      };

    default:

      return state;
  }
};
