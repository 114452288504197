const initialState = {
  candleData: [],
  lineData: [],
  volumeData: [],
  activeData: {},
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case "SET_CHART_DATA":
      let candleData = payload.map((e) => ({ ...e, time: e.closeTime }));
      return {
        ...state,
        candleData,
        lineData: candleData.map((e) => ({ time: e.time, value: e.close })),
        volumeData: candleData.map((e) => ({
          time: e.time,
          value: e.volume,
          color: e.close > e.open ? "#4bffb5" : "#ff4976",
        })),
      };
    case "UPDATE_CHART_DATA":
      const updateState = getUpdatedState(state.candleData, payload);
      return {
        ...state,
        candleData: updateState.map((e) => ({ ...e, time: e.closeTime })),
        lineData: updateState.map((e) => ({
          time: e.closeTime,
          value: e.close,
        })),
        volumeData: updateState.map((e) => ({
          time: e.closeTime,
          value: e.volume,
          color: e.close > e.open ? "#4bffb5" : "#ff4976",
        })),
      };
    default:
      return state;
  }
};

// const getUpdatedState = (oldChart, newEntry) => {
//   let index = oldChart.findIndex((x) => newEntry.eventTime < x.time);
//   if (index !== -1) {
//     oldChart[index] = newEntry;
//   } else {
//     if (newEntry.isFinal) {
//       oldChart.push({
//         time: newEntry.eventTime,
//         ...newEntry,
//       });
//     } else if (oldChart.length) {
//       oldChart[oldChart.length - 1] = {
//         time: newEntry.eventTime,
//         ...newEntry,
//       };
//     } else {
//       oldChart.push(newEntry);
//     }
//   }
//   return oldChart;
// };

const getUpdatedState = (oldChart, newEntry) => {
  if (!newEntry.isFinal) {
    oldChart[oldChart.length - 1] = {
      time: newEntry.eventTime,
      ...newEntry,
    };
  } else {
    oldChart.push({
      time: newEntry.eventTime,
      ...newEntry,
    });
  }

  return oldChart;
};
