import {
  PAYOUT_LOAD, PAYOUT_LOADING
} from "../constants";

const initialState = {
  loading: false,
  payouts: [],
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case PAYOUT_LOADING:
      return {
        loading: true,
        payouts: []

      };

    case PAYOUT_LOAD:
      return {
        ...state,
        loading: false,
        payouts: payload
      };

    default:
      return state;
  }
};
