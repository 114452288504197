import {
  ORDERS_LOADING, ORDERS_LOAD,
} from "../constants";

const initialState = {
  loading: false,
  orders: [],
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case ORDERS_LOADING:
      return {
        loading: true,
        orders: []

      };

    case ORDERS_LOAD:
      return {
        ...state,
        loading: false,
        orders: payload
      };

    default:
      return state;
  }
};
