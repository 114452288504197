
const dark = require('../../data/dark.json');
const light = require('../../data/light.json');
const initialState = {
    theme: 'dark',
    colors: dark,

};

export default (state = initialState, { type, payload }) => {
    switch (type) {
        case 'TOGGLE_THEME':
            return {
                theme:payload,
                colors: payload==='dark'?dark:light
            };
            break;

        default:
            return state;
    }
};