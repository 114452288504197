import { combineReducers } from "redux";

import authReducer from "./auth";
import errorReducer from "./error";

import orders from "./orders";
import tickets from "./tickets";
import config from "./config";
import payouts from "./payouts";
import users from "./users";
import menu from "./menu";
import binance from "./binance";
import misc from "./misc";
import langReducer from "./lang";
import theme from "./theme";
import trade from "./trade";
import announcement from "./announcement";
import chart from "./chart";
import chartMisc from "./chartMisc";

export default combineReducers({
  auth: authReducer,
  error: errorReducer,
  orders,
  tickets,
  config,
  payouts,
  users,
  menu,
  binance,
  misc,
  lang: langReducer,
  theme,
  trade,
  announcement,
  chart,
  chartMisc,
});
