const initialState = {
    fav: [],
    selected: 'BTCUSDT',
    price:0,

};

export default (state = initialState, { type, payload }) => {

    switch (type) {
        case 'ADD_FAV':
            let fav = state.fav;
            fav.push(payload.data)
            return {
                ...state,
                fav: fav
            };
            break;

        case 'REMOVE_FAV':
            fav = state.fav;
            let index = state.fav.findIndex(x => x.symbol == payload.data.symbol);
            if (index != -1) {
                fav.splice(index, 1);

            }
            return {
                ...state,
                fav: fav
            };
            break;
        case 'SET_SELECTED':
            return {
                ...state,
                selected: payload.data
            };
            break;
        case 'TOGGLE_SYMBOL':
            fav = state.fav;
            index = fav.findIndex(x => x.symbol === payload.symbol)
            console.log(index)
            if (index > -1) {
                fav.splice(index, 1);
            } else {
                fav.push(payload)
            }
            console.log(fav)
            return {
                ...state,
                fav
            };
            break;
        
        case 'UPDATE_PRICE':
            return {
                ...state,
                price:payload
            }
        default:
            return state;
    }
};