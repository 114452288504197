import {
  USERS_LOAD, USERS_LOADING, ONLINE_USERS_LOADING, ONLINE_USERS_LOAD
} from "../constants";

const initialState = {
  usersLoading: false,
  onlineUsersLoading: false,
  onlineUsers: [],
  users: [],
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case USERS_LOADING:
      return {
        ...state,
        usersLoading: true,
        users: []

      };

    case USERS_LOAD:

      return {
        ...state,
        usersLoading: false,
        users: payload
      };
    case 'USER_ENTRY_UPDATE':
      let { users, onlineUsers } = state
      users = users.length ? users.map(p =>
        p._id === payload._id ? payload
          : p
      ) : []

      let onlineIndex = onlineUsers.findIndex(x => x._id == payload._id)
      if (onlineIndex != -1 && payload.status == 'offline') {

        onlineUsers.splice(onlineIndex, 1)
      } else {
        onlineUsers.push(payload)
      }


      return {
        ...state,
        users,
        onlineUsers,

      };


    case ONLINE_USERS_LOADING:
      return {
        ...state,
        onlineUsersLoading: true,
        onlineUsers: []

      };

    case ONLINE_USERS_LOAD:
      return {
        ...state,
        onlineUsersLoading: false,
        onlineUsers: payload
      };

    default:
      return state;
  }
};
