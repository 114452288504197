

const initialState = {
    openOrders: [],
    profit: 0,
    loss:0
};

export default (state = initialState, { type, payload }) => {
    switch (type) {
        case 'SET_OPEN_ORDERS':
            if (payload.length) {
                const profit = payload.filter(e => e.changeStat === 'up').reduce((a, b) => a + b.change, 0);
                const loss = payload.filter(e => e.changeStat === 'down').reduce((a, b) => a + b.change, 0);
                return {
                    openOrders: payload,
                    profit,
                    loss
                }
            } else {
                return initialState
            }
        case 'UPDATE_OPEN_ORDER':
            const index = state.openOrders.findIndex(e => e._id == payload._id);
            if (index!= -1) {
                state.openOrders[index] = payload
            }
            return  {
                openOrders: state.openOrders,
                profit: state.openOrders.filter(e => e.changeStat === 'up').reduce((a, b) => a + b.change, 0),
                loss: state.openOrders.filter(e => e.changeStat === 'down').reduce((a, b) => a + b.change, 0),
          
            };
          
        case 'ADDTO_OPEN_ORDER':
            state.openOrders.push(payload)
            return {
                openOrders: state.openOrders,
                profit: state.openOrders.filter(e => e.changeStat === 'up').reduce((a, b) => a + b.change, 0),
                loss: state.openOrders.filter(e => e.changeStat === 'down').reduce((a, b) => a + b.change, 0)
            };
        case 'ORDER_CLOSED':

            state.openOrders.findIndex(e => e._id == payload._id);
            if (index != -1) {
                state.openOrders.splice(index, 1);
            }
            return {
                openOrders: state.openOrders,
                profit: state.openOrders.filter(e => e.changeStat === 'up').reduce((a, b) => a + b.change, 0),
                loss: state.openOrders.filter(e => e.changeStat === 'down').reduce((a, b) => a + b.change, 0)
            };
        case 'FINAL_CLOSE':
            return initialState
        default:
            return state;
        
    }
};
