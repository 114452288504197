
const initialState = {
  isAuthenticated: false,
  loading: false,
  user: {},
  token: "",
  emailToken: '',
  emailTokenDate: '',
  tfaEnabled: false,
  notifications: [],
  walletHistory: [],
  orders: [],
  tickets: [],
  config: {},
  tfaVarified: false,
  theme: 'dark',
  total: { deposit: 0, widrawal: 0, profit: 0, loss: 0 },
  mainLoading: false,
  manual: [],
  counter: 0,
  mainLoading: false,
  forgotNav: false,
  forgotUser: {}
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case 'LOAD_USER':
      return {
        ...state,
        user: payload.user,
        token: payload.token,
        isAuthenticated: true,
        loading: false,
        notifications: payload.notifications,
        walletHistory: payload.walletHistory,
        orders: payload.orders,
        tickets: payload.tickets,
        config: payload.config,
        manual: payload.manual ? payload.manual : [],
        tfaVarified: false,
        total: payload.total
      };
      break;
    case 'UNLOAD_USER':
      return {
        ...state,
        user: {},
        token: "",
        isAuthenticated: false,
        tfaVarified: false
      };
      break;
    case 'TFA_VARIFIED':
      return {
        ...state,
        tfaVarified: true
      };
      break;
    case 'MASTER_LOADING_ON':
      return {
        ...state,
        mainLoading: true

      }
    case 'MASTER_LOADING_OFF':
      return {
        ...state,
        mainLoading: false

      }
    case 'SET_EMAIL_TOKEN':
      return {
        ...state,
        emailToken: payload.token,
        emailTokenDate: payload.tokenDate,
      };
      break;

    case 'SET_MASTER_DATA':
      return {
        ...state,
        ...payload,
        loading: false,
      }
    case 'SET_USER':
      return { ...state, user: payload };
      break;
    case 'SET_NOTIFICATIONS':
      return { ...state, notifications: payload };
      break;
    case 'SET_WALLET_HISTORY':
      return { ...state, walletHistory: payload };
      break;
    case 'SET_TOTAL':
      return { ...state, total: payload };
      break;
    case 'SET_ORDERS':
      return { ...state, orders: payload };
      break;
    case 'SET_TICKETS':
      return { ...state, tickets: payload };
      break;
    case 'SET_CONFIG':
      return { ...state, config: payload };
      break;
    case 'UPDATE_USER':
      return { ...state, user: payload };
      break;
    case 'LOADING_ON':
      return { ...state, loading: true }
      break
    case 'LOADING_OFF':
      return { ...state, loading: false };
    case 'ENABLE_TFA':
      return { ...state, tfaEnabled: true };
    case 'SET_FORGOT_USER':
      return {
        ...state,
        forgotNav: true,
        forgotUser: payload
      }
    case 'CLEAR_FORGOT_USER':
      return {
        ...state,
        forgotNav: false,
        forgotUser: {}
      }
    case 'SET_ERROR':
      if (payload.resposnseCode == 401)
        return {
          ...initialState,
          forgotNav: state.forgotNav,
          forgotUser: state.forgotUser
        }
      else return state
    case 'SET_TOTAL':
      return { ...state, total: payload };
      break;
    case 'SET_MANUAL':
      return { ...state, manual: payload };
      break;
    case 'SET_COUNTER':
      return {
        ...state,
        counter: payload,
      }

    case 'TFA_ACTIVATED':
      return {
        ...state,
        tfaStatus: true,
        tfaVarified: true,

      }
    default:
      return state;
  }
};
