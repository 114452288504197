import {
  CONFIG_LOAD, CONFIG_LOADING,
} from "../constants";

const initialState = {
  loading: false,
  data: {},
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case CONFIG_LOADING:
      return {
        loading: true,
        data: {}

      };

    case CONFIG_LOAD:
      return {
        ...state,
        loading: false,
        data: payload
      };

    default:
      return state;
  }
};
