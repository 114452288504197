const initialState = {
    data: [],
};

export default (state = initialState, { type, payload }) => {
    switch (type) {
        case 'ADD_ANNOUNCEMENT':
            state.data.unshift(payload)
            return {
              data:state.data
            };

        case 'REMOVE_ANNOUNCEMENT':
            let index = state.data.findIndex(e => e._id == payload._id);
            if (index != -1) {
                state.data.splice(index,1)
            }
            return {
                data: state.data
            };
        case 'UPDATE_ANNOUNCEMENT':
             index = state.data.findIndex(e => e._id == payload._id);
            if (index != -1) {
                state.data[index]=payload
            }
            return {
                data: state.data
            };

            
        case 'LOAD_ANNOUNCEMENT':
            return {
                data: payload
            };
        default:

            return state;
    }
};
