import socketIOClient from "socket.io-client";
var Raxios = require("axios");
// import { SITE_URL } from "@env"

const SITE = 'https://api.cryptinix.com';
const SITE_URL = 'https://api.cryptinix.com/api';
const SOCKET_URL = 'https://api.cryptinix.com';

// kooliyadan - wifi
// const SITE = "http://192.168.100.166:8080";
// const SITE_URL = "http://192.168.100.166:8080/api";
// const SOCKET_URL = "http://192.168.100.166:8083";


let axios = new Raxios.create({
  baseURL: SITE_URL,
  header: {
    "Content-type": "application/json",
  },
});
axios.interceptors.response.use(
  (res) => {
    return Promise.resolve(res.data);
  },
  (error) => {
    let err = {
      ...error.response.data,
      resposnseCode: error.response.status,
    };
    return Promise.reject(err);
  }
);

axios.interceptors.request.use((req) => {
  // console.log(req);
  return Promise.resolve(req);
});

const getHeader = (auth) => {
  return {
    headers: {
      Authorization: "Bearer " + (auth.isAuthenticated ? auth.token : ""),
      "Content-Type": "application/json;charset=utf-8",
    },
  };
};

const socket = socketIOClient(SOCKET_URL, {
  transports: ["websocket", "polling", "flashsocket"],
  secure: true,
});

export { axios, getHeader, SOCKET_URL, socket, SITE };
