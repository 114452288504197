import {
  TICKETS_LOADING, TICKETS_LOAD,
} from "../constants";

const initialState = {
  loading: false,
  tickets: [],
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case TICKETS_LOADING:
      return {
        loading: true,
        tickets: []
      };

    case TICKETS_LOAD:
      return {
        ...state,
        loading: false,
        tickets: payload
      };

    default:
      return state;
  }
};
